<template>
  <ib-navigation-button-next
    :disabled="isStartBtnDisable"
    :text="buttonText"
    :title="buttonTitle"
    @click="onClick"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IbNavigationButtonNext from '@/components/_v2/Button/IbNavigationButtonNext'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'IdeaScoreStartButton',

  components: { IbNavigationButtonNext },

  mixins: [MixinIdeaRoles, MixinUserSubscription],

  computed: {
    ...mapGetters('idea', ['isAllStepsCompleted', 'getIdeaScore']),

    buttonText () {
      // if (this.isUserFreeTrialOrDreamer && !this.isStartBtnDisable) {
      //   return this.$t('upgradeToContinue')
      // }

      if (this.getIdeaScore) {
        return this.$t('continue')
      }

      return this.$t('start')
    },

    buttonTitle () {
      return this.getIdeaScore ? `${this.getIdeaScore.progress}% ${this.$t('pages.businessGuide.validation.ideaScore.completed')}` : null
    },

    isUserFreeTrialOrDreamer () {
      return this.subscriptionIsFreeTrial || this.subscriptionIsDreamer
    },

    isStartBtnDisable () {
      return [
        this.isRoleOwner
      ].some(v => v === false)
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogUpgradeState']),

    onClick () {
      // if (this.isUserFreeTrialOrDreamer) {
      //   this.setDialogUpgradeState(true)
      //
      //   return
      // }
      this.$emit('click')
    }
  }
}
</script>

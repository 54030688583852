<template>
  <div id="story-mode-validation">
    <!-- Container -->
    <div class="container-1200">
      <!-- Content -->
      <div class="content">

        <header class="page-header idea-score-header">
          <!-- Dashboard Header -->
          <dashboard-header
            :title="$t('sidebar.validation')"
            hide-quick-tour-button
            :guide-visible="guideVisible"
            @toggle-guide="guideVisible = !guideVisible"
            @open-info="guideVisible = true"
          />
          <!-- /Dashboard Header -->

          <!-- Divider -->
          <hr>
          <!-- /Divider -->
        </header>
        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <el-row :gutter="20" class="d-flex flex-wrap flex-column-reverse flex-xl-row mt-4">
          <el-col :md="24" :xl="16">
            <idea-score-cards />
          </el-col>
          <el-col :md="24" class="mb-5 mb-xl-0 d-xl-none">
            <idea-score-start-button
              class="m-auto mobile-score-btn"
              @click="onStartBtnClick"
            />
          </el-col>
          <el-col :md="24" :xl="8" class="d-flex mb-3 mb-xl-0">
            <div class="d-flex score-progress-card">
              <idea-score-progress-card />
            </div>
          </el-col>
        </el-row>

        <div class="d-none d-xl-flex justify-content-end mt-4">
          <idea-score-start-button @click="onStartBtnClick" />
        </div>
      </div>
      <!-- /Content -->
    </div>
    <!-- /Container -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="ideaScoreExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Idea Score Init -->
    <dialog-validation-init
      :visible="dialogValidationInitVisible"
      @close="dialogValidationInitVisible = false"
    />
    <!-- /Dialog Idea Score Init -->

    <!-- Idea Score Video Quick Tour -->
    <dialog-idea-score-quick-tour :visible="dialogQuickTourVisible" />
    <!-- Idea Score Video Quick Tour -->
  </div>
</template>

<script>
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogIdeaScoreQuickTour from '@/views/Home/Components/Dialogs/DialogIdeaScoreQuickTour.vue'
import DialogValidationInit from '@/views/Home/StoryMode/Validation/IdeaScore/Dialogs/DialogValidationInit'
import IdeaScoreCards from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreCards'
import IdeaScoreProgressCard from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreProgressCard'
import IdeaScoreStartButton from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreStartButton'
import MixinGuide from '@/mixins/guide'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

import { mapActions, mapGetters } from 'vuex'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'IdeaScore',

  components: {
    InfoGuideDrawer,
    DialogIdeaScoreQuickTour,
    DashboardHeader,
    DialogValidationInit,
    IdeaScoreCards,
    IdeaScoreProgressCard,
    IdeaScoreStartButton
  },

  mixins: [MixinGuide, MixinUserSubscription],

  data () {
    return {
      dialogValidationInitVisible: false,
      dialogQuickTourVisible: false,
      guideVisible: false
    }
  },

  computed: {
    ...mapGetters('idea', {
      ideaScore: 'getIdeaScore'
    })
  },

  created () {
    this.getIdeaScore()

    if (this.subscriptionIsDreamer) {
      this.dialogQuickTourVisible = true
    }

    if (!this.subscriptionIsDreamer) {
      this.openGuide('ideaScoreGuide')
    }
  },

  methods: {
    ...mapActions('idea', [
      'getIdeaScore'
    ]),

    onStartBtnClick () {
      if (!this.ideaScore) {
        this.dialogValidationInitVisible = true

        return
      }

      this.$router.push({ name: 'validation-idea-score-test' })
    }
  }
}
</script>

<style scoped lang="scss">
.idea-score-header {
  @include media-breakpoint-up($md) {
    margin-top: 32px;
  }
}
#story-mode-validation {
  margin-left: 0;

  //.content {
  //  padding-top: 12px;
  //
  //  @include media-breakpoint-up($md) {
  //    padding-top: 32px;
  //  }
  //}

  .score-progress-card {
    width: 100%;

    @include media-breakpoint-up($sm) {
      width: 400px;
      margin: 0 auto;
    }

    @include media-breakpoint-up($xl) {
      width: 100%;
    }
  }

  .mobile-score-btn {
    width: 100%;

    @include media-breakpoint-up($sm) {
      width: unset;
    }
  }
}
</style>
